import React, {Component} from 'react'
import {graphql} from 'gatsby'
import Layout from 'components/Layout'
import {Container, Row, Col} from 'reactstrap'
import BoohBannerMob from 'images/booh-sub-576.jpg'
import BoohBannerTablet from 'images/booh-sub-991.jpg'
import BoohBannerDesk from 'images/booh-sub-1920.jpg'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Img from 'gatsby-image'
import styled from "styled-components"
import {media} from "utils/Media"


const headData = {
    title: 'Bat out of Hell! | In Memoriam',
    description: 'Bat Out Of Hell! The Musical dedicates this tour to the memory of Jim Steinman (November 1, 1947 - April 19, 2021) and Meat Loaf (September 27, 1947 - January 20 , 2022)',
};

const Title = styled.div`
        font-size: 24px;
        line-height: 24px;
        font-family: ${props => props.theme.font.family.bold};
        font-weight: bold;
        flex-grow: 1;
        padding-top: 5px;
        text-transform: uppercase;
        text-align: center;
        
        @media ${media.sm} {
            padding-top: 0;
            line-height: 30px;
        }
        
        @media ${media.lg} {
            font-size: 28px;
            line-height: 36px;   
        }
`

class MemoriamPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
            modalOpen: false,
        }
    }

    toggleModal = (e) => {
        e.preventDefault();

        this.setState({
            modalOpen: !this.state.modalOpen
        });
    };

    render() {
        const {isOpen, photoIndex} = this.state

        const items = this.props.data.allMemoriamJson.edges

        let images = []

        const galleryItems = items.map((item, i) => {
            images.push(item.node.image.childImageSharp.fluid.src)
            return (
                <Col xs={12} sm={6} lg={3} key={i} className="px-0 py-3 p-md-3">
                    <div
                        tabIndex={i} role="button" aria-pressed="false"
                        className="photo-wrapper"
                        onClick={() => this.setState({photoIndex: i, isOpen: true})}
                        onKeyDown={() => this.setState({photoIndex: i, isOpen: true})}
                    >
                        <Img
                            fluid={item.node.thumb.childImageSharp.fluid}
                            alt=""
                            className="photo-wrapper__img"
                        />
                    </div>
                </Col>
            )
        })

        return (
            <Layout
                headData={headData}
                navGlobal={true}
                path="/media/"
                uk
            >
                <section className="page bg--black">
                    <div className="page-container">
                        <Container fluid={true} className="px-0">

                            <div>
                                <img src={BoohBannerMob} alt="Bat Out Of Hell!" className="img-fluid w-100 d-md-none"/>
                                <img src={BoohBannerTablet} alt="Bat Out Of Hell!"
                                     className="img-fluid w-100 d-none d-md-block d-lg-none"/>
                                <img src={BoohBannerDesk} alt="Bat Out Of Hell!"
                                     className="img-fluid w-100 d-none d-lg-block"/>
                            </div>

                            <h1 className="big-text text-center pt-5 text--red">In Memoriam</h1>


                            <Container>
                                <div className="photos">
                                    <Title>Jim Steinman & Meat Loaf</Title>
                                    <p style={{textAlign: "center"}}> Bat Out Of Hell! The Musical dedicates this tour to the memory of Jim Steinman (November 1, 1947 - April 19, 2021) and Meat Loaf (September 27, 1947 - January 20 , 2022)
                                    </p>
                                    <Title>The beat is yours forever.</Title>
                                </div>
                            </Container>
                            <Container>

                                <div className="photos">
                                    <Row className="justify-content-center">
                                        {galleryItems}
                                    </Row>
                                    {/*<p className="py-5 text-center mb-0">Footage of previous productions. Photos of the 2021 Cast.  Photography by Chris Davis</p>*/}
                                </div>

                            </Container>
                        </Container>
                    </div>
                </section>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        imageCaption={''}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                            })
                        }
                    />
                )}
            </Layout>
        )
    }

}


export default MemoriamPage

export const MemoriamPageQuery = graphql`
query {
    allMemoriamJson {
        edges {
            node {
                id
                image {
                    childImageSharp {
                        fluid(maxWidth: 1200) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                thumb {
                    childImageSharp {
                        fluid(maxWidth: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
}`